$(document).ready(function () {

    $("a.scrollTo").click(function () {
        $("html, body").animate({
            scrollTop: $($(this).attr("href")).offset().top + "px"
        }, {
            duration: 500,
            easing: "swing"
        });
        return false;
    });


    $(".tabs__link").click(function () {
        var top = $($(this).attr("href")).offset().top - 80;
        $("html, body").animate({

            scrollTop: top + "px"
        }, {
            duration: 500,
            easing: "swing"
        });
        return false;
    });


    $(document).on('mse2_load', function (e, data) {

        if (lazyLoadInstance) {
            lazyLoadInstance.update();
        }

    });

    var lazyLoadInstance = new LazyLoad({
        elements_selector: ".lazy",
    });

    $('#showFilter').on('click', function () {
        $('aside').slideToggle(500);
    })


    $('#toTop').click(function () {
        $('body,html').animate({scrollTop: 0}, 800);
        return false;
    });

    $(window).scroll(function () {
        if ($(this).scrollTop() > 200) {
            $('#toTop').fadeIn();
        } else {
            $('#toTop').fadeOut();
        }

    });


    if($('.gallery__thumbs').length){
        $('.slider-for').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            fade: true,
            dots: false,
            asNavFor: '.slider-nav'
        });

        $('.slider-nav').slick({
            slidesToShow: 5,
            slidesToScroll: 1,
            asNavFor: '.slider-for',
            dots: false,
            focusOnSelect: true,
            responsive: [
                {
                    breakpoint: 2400,
                    settings: {
                        vertical:true,
                    }
                },
                {
                    breakpoint: 480,
                    settings: {
                        vertical:false,
                    }
                }
            ],
        });
    }

    if($('.slider').length){
        $('.slider').slick({
            slidesToShow: 1,
            slidesToScroll: 1,
            arrows: false,
            dots: true,
            adaptiveHeight: true,
            autoplay: true
        })
            .on('beforeChange', function () {
                var $slide = document.querySelector('.slick-active')
                var $slide__name = $slide.querySelector('.slider__name');
                var $slider__text = $slide.querySelector('.slider__text');
                var $slider__button = $slide.querySelector('.slider__button');

                if($slide__name){
                    $slide__name.classList.remove('animated', 'fadeInDown', 'faster');
                }
                if($slider__text){
                    $slider__text.classList.remove('animated', 'fadeInDown', 'fast');
                }
                if($slider__button){
                    $slider__button.classList.remove('animated', 'fadeInDown', 'fast');
                }

            })
            .on('afterChange', function (event, slick, currentSlide, nextSlide) {
                var $slide = document.querySelector('.slick-active')
                var $slide__name = $slide.querySelector('.slider__name');
                var $slider__text = $slide.querySelector('.slider__text');
                var $slider__button = $slide.querySelector('.slider__button');
                if($slide__name){
                    $slide__name.classList.add('animated', 'fadeInDown', 'faster');
                }
                if($slider__text){
                    $slider__text.classList.add('animated', 'fadeInDown', 'fast');
                }
                if($slider__button){
                    $slider__button.classList.add('animated', 'fadeInDown', 'fast');
                }

            });
        var $slide = document.querySelector('.slick-active')
        var $slide__name = $slide.querySelector('.slider__name');
        var $slider__text = $slide.querySelector('.slider__text');
        var $slider__button = $slide.querySelector('.slider__button');
        if($slide__name){
            $slide__name.classList.add('animated', 'fadeInDown', 'slow');
        }
        if($slider__text){
            $slider__text.classList.add('animated', 'fadeInDown', 'slow');
        }
        if($slider__button){
            $slider__button.classList.add('animated', 'fadeInDown', 'slow');
        }
    }


    $('.solutions__link').hover(function () {
        $(this).find('svg').addClass('animated heartBeat');
    }, function () {
        $(this).find('svg').removeClass('animated heartBeat')
    });

    if ($(".iziModal").length) {
        $(".iziModal").iziModal({
            overlayColor: 'rgba(0, 0, 0, 1)',
            padding: 50,
            width: 600,
            headerColor: '#146db6',
        });
    }

    if ($('.iziModal-trigger').length) {
        $(document).on('click', '.iziModal-trigger', function (event) {
            event.preventDefault();
        });
    }


    var $custom_checkbox__inputs = document.querySelectorAll('.custom_checkbox__input');
    $custom_checkbox__inputs.forEach(function ($custom_checkbox__input) {
        $custom_checkbox__input.addEventListener('change', function (event) {
            var $modal__form = event.target.closest('.modal__form');
            var $modal__button = $modal__form.querySelector('button');
            if(event.target.checked){
                $modal__button.disabled = false;
            }else{
                $modal__button.disabled = true;
            }
        });
    });

});


function isMobile() {
    var window_width = $(window).width();

    if (window_width <= 425) {
        return true;
    }

    return false;
}


var $header__menu_button = document.querySelector('.header__menu_button');
var $sidenav__back = document.querySelector('.sidenav__back');
var $catalog__close = document.querySelector('.catalog__close');
var $header__catalog_button = document.querySelector('.catalog__button');
var $sidenav = document.querySelector('.sidenav');
var $overlay = document.getElementById('overlay');
var $buttons = document.querySelectorAll('.btn');
var $solutions__link = document.querySelectorAll('.solutions__link');
var $showFilter = document.getElementById('showFilter');
var $header__catalog_list = document.querySelector('.catalog__list');
// var $gallery__thumbs = document.querySelectorAll('.gallery__thumb_link');
// var $gallery__fancybox_links = document.querySelectorAll('.gallery__fancybox_link');


$header__menu_button.addEventListener('click', function () {
    if ($sidenav.classList.contains('sidenav__list--show')) {
        hideSideNav();
    } else {
        showSideNav();
    }
});


$sidenav__back.addEventListener('click', hideSideNav);
$catalog__close.addEventListener('click', hideCatalog);

$overlay.addEventListener('click', hideSideNav);


function showSideNav() {
    $sidenav.classList.remove('sidenav__list--hide');
    $sidenav.classList.add('sidenav__list--show');
    $overlay.classList.add('active');
}

function hideSideNav() {
    $sidenav.classList.add('sidenav__list--hide');
    $sidenav.classList.remove('sidenav__list--show');
    $overlay.classList.remove('active');
}

function hideCatalog() {
    $header__catalog_list.classList.remove('catalog__list--open');
}

$header__catalog_button.addEventListener('click', function (event) {
    event.preventDefault();

    if ($header__catalog_list.classList.contains('catalog__list--open')) {
        $header__catalog_list.classList.remove('catalog__list--open')
    } else {
        $header__catalog_list.classList.add('catalog__list--open')
    }

});


for (var i = 0; i < $buttons.length; i++) {
    $buttons[i].addEventListener('mouseover', function (event) {
        var $svg = event.target.querySelector('svg');
        if ($svg) {
            $svg.classList.add('animated', 'heartBeat')
        }
    });

    $buttons[i].addEventListener('mouseout', function (event) {
        var $svg = event.target.querySelector('svg');
        if ($svg) {
            $svg.classList.remove('animated', 'heartBeat')
        }
    });
}


